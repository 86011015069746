/* いる */
label {
  color: rgb(0, 0, 0.87);
  font-weight: 300;
  letter-spacing: 0.025em;
}

.credit_card_register button {
  white-space: nowrap;
  border: 0;
  outline: 0;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  padding: 0 14px;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  font-weight: 600;
  font-family: 'Roboto,Helvetica,Arial,sans-serif';
  text-transform: uppercase;
  letter-spacing: 0.025em;
  background-color: #e0a86e;
  text-decoration: none;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
  margin-top: 10px;
}

form {
  margin-bottom: 40px;
  padding-bottom: 40px;
  /* border-bottom: 3px solid #e6ebf1; */
}

.credit_card_register button:hover {
  /* color: #fff; */
  cursor: pointer;
  background-color: #9c754d;
  transform: translateY(-1px);
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}

/* いる */
input,
.StripeElement {
  display: block;
  margin: 10px 0 20px 0;
  max-width: 500px;
  padding: 10px 14px;
  font-size: 1em;
  font-family: 'familyRoboto, Helvetica, Arial, sans-serif';
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 0;
  outline: 0;
  border-radius: 4px;
  background: white;
}

input:focus,
.StripeElement--focus {
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px, rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

.StripeElement.IdealBankElement,
.StripeElement.PaymentRequestButton {
  padding: 0;
}
